.settings .avatar {
  width: 256px;
  height: 256px;
  background-size: cover;
  border-radius: 100%;
  cursor: pointer;
  margin-bottom: 20px;
}

.settings .banner {
  width: 300px;
  height: 150px;
  background-size: cover;
  margin-bottom: 20px;
}

.settings .image-settings {
  display: block;
  align-items: center;
  justify-content: center;
}

.settings .image-setting {
  display: flex;
}

.settings .image-setting > div:first-child {
  align-self: center;
}

.settings .avatar,
.settings .banner {
  margin-left: auto;
}

.settings .avatar .edit,
.settings .banner .edit {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  opacity: 0;
  background-color: var(--bg-color);
}

.settings .avatar .edit:hover {
  opacity: 0.5;
}

.settings .editor textarea {
  resize: vertical;
  max-height: 300px;
  min-height: 40px;
}

.settings .actions {
  margin-top: 16px;
}
