.note {
  min-height: 110px;
}

.note > .header .reply {
  font-size: 13px;
  color: var(--font-secondary-color);
}

.note > .header .reply a {
  color: var(--highlight);
}

.note > .header .reply a:hover {
  text-decoration-color: var(--highlight);
}

.note > .header > .info {
  font-size: var(--font-size);
  margin-left: 4px;
  white-space: nowrap;
  color: var(--font-secondary-color);
  display: flex;
  align-items: center;
}

.note > .header > .info .saved {
  margin-right: 12px;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.11em;
  text-transform: uppercase;
  display: flex;
  align-items: center;
}
.note > .header > .info .saved svg {
  margin-right: 8px;
}

.note > .header > .pinned {
  font-size: var(--font-size-small);
  color: var(--font-secondary-color);
  font-weight: 500;
  line-height: 22px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.note > .header > .pinned svg {
  margin-right: 8px;
}

.note > .body {
  margin-top: 4px;
  margin-bottom: 24px;
  padding-left: 56px;
  text-overflow: ellipsis;
  white-space: pre-wrap;
  word-break: normal;
  overflow-x: hidden;
  overflow-y: visible;
}

.note > .footer {
  padding-left: 46px;
}

.note .footer .footer-reactions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: auto;
}

@media (min-width: 720px) {
  .note .footer .footer-reactions {
    margin-left: 0;
  }
}

.note > .footer .ctx-menu {
  color: var(--font-secondary-color);
  background: transparent;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.4);
  min-width: 0;
  margin: 0;
  padding: 0;
  border-radius: 16px;
}

.light .note > .footer .ctx-menu {
}

.note > .footer .ctx-menu li {
  background: #1e1e1e;
  padding-top: 8px;
  padding-bottom: 8px;
  display: grid;
  grid-template-columns: 2rem auto;
}

.light .note > .footer .ctx-menu li {
  background: var(--note-bg);
}

.note > .footer .ctx-menu li:first-of-type {
  padding-top: 12px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}
.note > .footer .ctx-menu li:last-of-type {
  padding-bottom: 12px;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}

.note > .footer .ctx-menu li:hover {
  color: white;
  background: #2a2a2a;
}

.light .note > .footer .ctx-menu li:hover {
  color: white;
  background: var(--font-secondary-color);
}

.ctx-menu .red {
  color: var(--error);
}

.note > .header img:hover,
.note > .header .name > .reply:hover,
.note .body:hover {
  cursor: pointer;
}

.note > .note-creator {
  margin-top: 12px;
  margin-left: 56px;
}

.reaction-pill {
  display: flex;
  margin: 0px 7px;
  padding: 2px 4px;
  min-width: 1rem;
  align-items: center;
  justify-content: center;
  user-select: none;
  color: var(--font-secondary-color);
  font-feature-settings: "tnum";
}

.reaction-pill-icon {
  margin: auto;
}

.reaction-pill .reaction-pill-number {
  margin-left: 8px;
  font-feature-settings: "tnum";
}

.reaction-pill.reacted {
  color: var(--highlight);
}

.reaction-pill:hover {
  cursor: pointer;
  color: var(--highlight);
}

.trash-icon {
  color: var(--error);
  margin-right: auto;
}

.note-expand .body {
  max-height: 300px;
  overflow-y: hidden;
}

.hidden-note .header {
  display: flex;
  align-items: center;
}

.card.note.hidden-note {
  min-height: unset;
}

.hidden-note button {
  max-height: 30px;
}

.expand-note {
  padding: 0 0 16px 0;
  font-weight: 400;
  color: var(--highlight);
  cursor: pointer;
}

.note.active {
  border-left: 1px solid var(--highlight);
  border-bottom-left-radius: 0;
  margin-left: -1px;
}

.note .reactions-link {
  color: var(--font-secondary-color);
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  margin-top: 4px;
  font-feature-settings: "tnum";
}

.note .reactions-link:hover {
  text-decoration: underline;
}

.close-menu {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: -400px;
  left: -600px;
}

.close-menu-container {
  position: absolute;
}
